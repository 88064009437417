import { CrmReturnValue } from 'common-module/core/types';
import { parseISO } from 'date-fns';

export type DateInput = Date | string;

export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;

export const getDate = <Value extends DateInput | undefined>(
  value: Value,
): CrmReturnValue<Value, Date> => {
  if (!value) {
    return undefined as CrmReturnValue<Value, Date>;
  }

  if (value instanceof Date) {
    return value;
  }

  return parseISO(value);
};
